<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-dialog v-model="isInfoOpen" v-if="$vuetify.breakpoint.smAndDown">
          <v-card>
            <v-card-text class="py-3">
              總訂單數 : {{ result.total }}
              <br />
              成功單數 : {{ statistics.paid_count || 0 }}
              <br />
              成功總金額 : {{ statistics.paid_amt || 0 }} 元
            </v-card-text>
          </v-card>
        </v-dialog>
        <div v-else class="d-flex align-center flex-wrap mb-4 mb-sm-0">
          總訂單數 : {{ result.total }}
          <v-divider vertical class="mx-3"></v-divider>
          成功單數 : {{ statistics.paid_count || 0 }}
          <v-divider vertical class="mx-3"></v-divider>
          成功總金額 : {{ statistics.paid_amt || 0 }} 元
        </div>

        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          :labels="labels"
          sheet-name="sheetName"
          :exclude="['actions']"
        />
      </v-card-text>
      <DataTable
        :options.sync="options"
        :list="result.list"
        :total="result.total"
        :fields="headers"
        :labels="labels"
        :replace="[
          'actions',
          'platform_order_no',
          'card.name_1',
          'payer_name',
          'amt',
          'created_at'
        ]"
        @get-list="fetchData"
      >
        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu>
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  v-show="!(key.hide && key.hide(item))"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template #item.platform_order_no="{ item }">
          <div>
            <div class="text-no-wrap">{{ item.platform_order_no || '-' }}</div>
            <div class="text-no-wrap">{{ item.merchant_order_no || '-' }}</div>
          </div>
        </template>
        <template #item.card.name_1="{ item }">
          <span class="Error500--text">{{ item.card.name_1 }}</span>
        </template>
        <template #item.payer_name="{ item }">
          <span class="Error500--text">{{ item.payer_name }}</span>
        </template>
        <template #item.amt="{ item }">
          <span class="Error500--text">{{ item.amt }}</span>
        </template>
        <template #item.created_at="{ item }">
          <div>
            <div class="text-no-wrap Error500--text">
              {{ item.created_at | formatTime }}
            </div>
            <div v-if="item.succeeded_at" class="text-no-wrap">
              {{ item.succeeded_at | formatTime }}
            </div>
            <div v-else class="text-no-wrap error--text">未完成</div>
          </div>
        </template>
      </DataTable>
    </v-card>

    <DetailForm
      :value="showingForm === 'Detail'"
      @close="showingForm = null"
      :passData="passData"
    />

    <ReplenishOrderForm
      :value="showingForm === 'ReplenishOrder'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="false"
    />
  </div>
</template>

<script>
import { getNotProcessedCollectOrders } from '@/api/collectOrders';

import DetailForm from '@/components/collect/order/DetailForm.vue';
import ReplenishOrderForm from '@/components/collect/order/ReplenishOrderForm.vue';

import orderStatus from '@/commons/order/status';
import clone from 'just-clone';

const labels = {
  status: clone(orderStatus),
  is_replenishment: [
    {
      color: 'Error400',
      text: '否',
      value: false
    },
    {
      color: 'Success300',
      text: '是',
      value: true
    }
  ]
};

const actions = [
  {
    label: '詳細',
    icon: 'mdi-filter-variant',
    grant: 'collect_order_list.details',
    action: (vm, v) => {
      vm.openForm('Detail', v);
    }
  },
  {
    label: '補單',
    icon: 'mdi-file-document-outline',
    grant: 'collect_order_list.replenishment',
    hide: (v) => v.is_replenishment,
    action: (vm, v) => {
      vm.openForm('ReplenishOrder', v);
    }
  }
];

export default {
  components: {
    DetailForm,
    ReplenishOrderForm
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),

      statistics: {
        paid_count: 0,
        paid_amt: 0
      },
      isInfoOpen: false,
      labels,
      headers: [
        { text: '操作', value: 'actions', align: 'center' },
        { text: '訂單狀態', value: 'status', align: 'center' },
        { text: '是否補單', value: 'is_replenishment', align: 'center' },
        {
          text: '平台單號\n/\n商戶單號',
          value: 'platform_order_no',
          align: 'center',
          join: 'merchant_order_no'
        },
        {
          text: '通道子帳戶',
          value: 'card.name_1',
          align: 'center',
          width: '160px'
        },
        {
          text: '付款人姓名',
          value: 'payer_name',
          align: 'center'
        },
        { text: '商戶 ID', value: 'merchant.serial', align: 'center' },
        {
          text: '商戶名稱',
          value: 'merchant.name',
          align: 'center',
          width: '120px'
        },
        {
          text: '訂單金額',
          sortable: true,
          value: 'amt',
          align: 'center',
          width: '120px'
        },
        {
          text: '校驗金額',
          sortable: true,
          value: 'verify_amt',
          align: 'center',
          width: '120px'
        },
        {
          text: '補單金額',
          sortable: true,
          value: 'replenishment_amt',
          align: 'center',
          width: '120px'
        },
        { text: '手續費', value: 'fee', align: 'center' },
        {
          text: '用戶入賬',
          value: 'credit_amt',
          align: 'center',
          width: '120px'
        },
        {
          text: '建立時間\n/\n成功時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center',
          join: 'succeeded_at'
        },
        {
          text: '通道',
          value: 'collect_channel.name',
          align: 'center',
          width: '80px'
        },
        { text: '回調標示', value: 'callback_note', align: 'center' },
        { text: '用戶 IP', value: 'client_ip', align: 'center' }
      ]
    };
  },

  watch: {
    options: {
      deep: true,
      handler(v) {
        const payload = {
          ...this.condition,
          sorts: [{ column: v.sortBy[0], sort: v.sortDesc[0] ? 'desc' : 'asc' }]
        };
        if (!v.sortBy.length) delete payload.sorts;
        this.fetchData(payload);
      }
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Orders = await getNotProcessedCollectOrders({
        ...this.filter,
        ...condition
      });
      if (!Orders.error) {
        this.result.total = Orders.total;
        this.result.page = Orders.page;
        this.statistics = Orders.statistics;
        this.$set(
          this.result,
          'list',
          Orders.items.map((item) => ({
            ...item,
            actions
          }))
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
